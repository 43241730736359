import React, { useEffect, useState } from 'react';

import { ReactComponent as ParcelIcon } from '../../assets/icons/parcel.svg';

import styles from './ParcelSizePriceSelect.module.css';
import { useSendParcel } from '../../context/SendParcelContext';
import { Button } from '../../components/Button/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDeliverParcel } from '../../context/DeliverParcelContext';
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import { ROUTE_PATHS } from '../../const/routes';

const ParcelSizePriceSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {t} = useTranslation();

  const {deliverParcelSizes, setParcelPrices, parcelPrices, handleDeliverParcelSizesChange} = useDeliverParcel();

  const localDeliverParcelSizes = location?.state?.sizes ? location?.state?.sizes : deliverParcelSizes
  const [localPrices, setLocalPrices] = useState(parcelPrices || []);

  // Обновление локальных цен
  const updatePrice = (id: number, increment: boolean) => {
    setLocalPrices((prevPrices) =>
      prevPrices.map((item) =>
        item.id === id
          ? { ...item, price: Math.max(0, item.price + (increment ? 1 : -1)) }
          : item
      )
    );
  };


  const onSelectClick = () => {
    const localStoreTgId = getHashedTgIdFromLocalStorage()
    setParcelPrices(localPrices);
    if (location?.state?.sizes) {
      handleDeliverParcelSizesChange(location?.state?.sizes)
    }
    navigate(
      `${location.pathname === ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT ? ROUTE_PATHS.DELIVER_PROVIDE_MORE_INFO : ROUTE_PATHS.CHECK_YOUR_DELIVER}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${localStoreTgId || searchParams.get('hashed-tg-id')}`
    );
  };



  return (
    <div className={styles.pageWrapper}>
      <div className={styles.topWidget}>
        <ParcelIcon/>
        {/*TODO need add LOCALIZATION*/}
        <h1 className={styles.title}>{t('parcel_size_price_page.title')}</h1>
        <p className={styles.subtitle}>
          {t('parcel_size_price_page.subtitle')}
        </p>
      </div>
      <div className={styles.parcelItems}>
        {localPrices && localPrices.length > 0 && localPrices.filter(item => localDeliverParcelSizes.find(size => {
          return size == item.title
        })).map((item) => {

          return (
            <div key={item.id} className={styles.priceItemWrapper}>
              <p
                className={styles.priceItemTitle}>{t(`possible_earnings_page.${item.title === 'Documents' ? 'documents' : item.title === 'S' ? 'size_s' : item.title.includes('M') ? 'size_m' : 'size_l'}`)}</p>
              <p
                className={styles.priceItemDescription}>{t(`parcel_size_price_page.${item.description.split(' ').join('_')}`)}</p>
              <div className={styles.controls}>
                <div onClick={() => updatePrice(item.id, false)} className={styles.control}>-</div>
                <p className={styles.price}>{item.price}$</p>
                <div onClick={() => updatePrice(item.id, true)} className={styles.control}>+</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.footer}>
        <Button color={'fill'} text={t('parcel_size_select_page.select_button')} size={'large'}
                onClick={onSelectClick}/>
      </div>
    </div>
  );
};

// @ts-ignore
export default ParcelSizePriceSelect;
