'use client';

import React, {
    createContext,
    useState,
    useContext,
    ReactNode,
} from 'react';
import {
    createTrip,
    getTripsSearchResult,
    GetTripsSearchResultsDto, makeNotification,
    MakeNotificationDto,
    PostTripDto
} from "../service/parcel/parcel";

export const BASE_URL = 'https://crowdsihipbc.online/api';

export interface ReviewInterface {
    id: string;
    author: string;
    recipient: string;
    rating: string;
    text: string;
}

interface DeliverParcelContextType {
    to: { title: string, placeId: string },
    from: { title: string, placeId: string },
    dateRange: any,
    parcelSize: string,
    additionalInfo: string,
    parcelPrices: any[],
    destination: DESTINATIONS,
    deliverParcelSizes: string[];
    setParcelPrices: React.Dispatch<React.SetStateAction<any[]>>;
    handleToChange: (data: { title: string, placeId: string }) => void,
    handleFromChange: (data: { title: string, placeId: string }) => void,
    handleParcelSizeChange: (data: string) => void,
    handleAdditionalInfoChange:  (data: string) => void,
    handleDestinationChange: (data: DESTINATIONS) => void;
    handlePostTrip: (id: string, data: PostTripDto) => Promise<any>;
    handleDeliverParcelSizesChange: (data: string[]) => void,
    handleMakeNotification: (id: string, data: MakeNotificationDto) => Promise<any>;
    handleSearchForParcelSend: (id: string, data: GetTripsSearchResultsDto) => Promise<any>;
    handleDateRangeChange: (data: [{ startDate: Date; endDate: Date; key: string }] | undefined) => void,
}

export enum DESTINATIONS {
    from = "from",
    to = "to",
}

const DeliverParcelContext = createContext<DeliverParcelContextType | undefined>(undefined);

export const DeliverParcelProvider = ({ children }: { children: ReactNode }) => {
    const [dateRange, setDateRange] = useState<any>(undefined);
    const [from, setFrom] = useState<{ title: string, placeId: string }>({ title: "", placeId: "" });
    const [to, setTo] = useState<{ title: string, placeId: string }>({ title: "", placeId: "" });
    const [parcelSize, setParcelSize] = useState<string>('');
    const [deliverParcelSizes, setDeliverParcelSizes] = useState<string[]>([]);

    const [parcelPrices, setParcelPrices] = useState<any[]>([{ id: 1, title: "Documents", description: "per item", price: 10 }, { id: 2, title: "S", description: "per item", price: 15 }, { id: 3, title: "M (up to 5 kg)", description: "per kg", price: 4 }, { id: 4, title: "L (up to 20 kg)", description: "per kg", price: 5 }]);

    const [destination, setDestination] = useState(DESTINATIONS.to);
    const [additionalInfo, setAdditionalInfo] = useState('');

    const handleDateRangeChange = (data: [{ startDate: Date; endDate: Date; key: string }] | undefined) => {
        setDateRange(data);
    };

    const handleDestinationChange = (data: DESTINATIONS) => {
        setDestination(data);
    };

    const handleAdditionalInfoChange = (data: string) => {
        setAdditionalInfo(data);
    };

    const handleFromChange = (data: { title: string, placeId: string }) => {
        setFrom(data);
    };

    const handleToChange = (data: { title: string, placeId: string }) => {
        setTo(data);
    };

    const handleParcelSizeChange = (data: string) => {
        setParcelSize(data);
    };

    const handleDeliverParcelSizesChange = (data: string[]) => {

        setDeliverParcelSizes(data);
    };

    const handleSearchForParcelSend = async (id: string, data: GetTripsSearchResultsDto) => {
        try {
            const results = await getTripsSearchResult(data, id);


            return results;
        } catch (err) {
            console.error(err)
        }
    }

    const handlePostTrip = async (id: string, data: PostTripDto) => {
        try {
            const response = await createTrip(id, data);


            return response;
        } catch (err) {
            console.error(err)
        }
    }

    const handleMakeNotification = async (id: string, data: MakeNotificationDto) => {
        try {
            const response = await makeNotification( data);


            return response;
        } catch (err) {
            console.error(err)
        }
    }

    const handleGetDeliverParcel = async (id: string) => {
        try {
            const profile = await fetch(`${BASE_URL}/reviews/`, {
                headers: {
                    "tg-id": id
                }
            }).then(res => res.json());

            if (profile) {
                return profile;
            } else {
                console.error('Failed to fetch Profile');
            }
        } catch (error) {
            console.error('Failed to fetch Profile', error);
        }
    };

    return (
        <DeliverParcelContext.Provider
            value={{
                to,
                from,
                dateRange,
                parcelSize,
                parcelPrices,
                destination,
                additionalInfo,
                deliverParcelSizes,
                handlePostTrip,
                handleToChange,
                handleFromChange,
                setParcelPrices,
                handleDateRangeChange,
                handleParcelSizeChange,
                handleMakeNotification,
                handleDestinationChange,
                handleSearchForParcelSend,
                handleAdditionalInfoChange,
                handleDeliverParcelSizesChange,
            }}
        >
            {children}
        </DeliverParcelContext.Provider>
    );
};

export const useDeliverParcel = (): DeliverParcelContextType => {
    const context = useContext(DeliverParcelContext);
    if (context === undefined) {
        throw new Error('useDeliverParcel must be used within an DeliverParcelProvider');
    }
    return context;
};
