import { makeAutoObservable } from 'mobx';

class GlobalRequestStore {
  settingsIsOpen: boolean = false;
  isLoading: boolean = false;
  error: string | [] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Запуск загрузки
  startLoading() {
    this.isLoading = true;
    this.error = null;
  }

  // Окончание загрузки
  finishLoading() {
    this.isLoading = false;
  }
  showSettings(){
    this.settingsIsOpen = true
  }
  hideSettings(){
    this.settingsIsOpen = false
  }
  // Установка ошибки
  setError(error: string | []) {
    this.isLoading = false;
    this.error = error;
  }

  // Сброс ошибки
  clearError() {
    this.error = null;
  }
}

const globalRequestStore = new GlobalRequestStore();
export default globalRequestStore;
