import React from 'react'
import styles from "./styles.module.css"
import {useTranslation} from "react-i18next";

interface IProps {
    text: string
}
 const AdditionalInfoBlock: React.FC<IProps> =({text}) =>{
    const { t } = useTranslation();
  if (!text) return  null
  return (
    <div className={styles.wrapper}>
        <span>{t('additional_info_block.additional_info')}</span>
        <p className={styles.content}>{text}</p>
    </div>
  )
}
export default AdditionalInfoBlock
