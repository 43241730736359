import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import ParcelDetail, { EImgType } from '../../components/ParcelDetail';

import ParcelCard from '../../components/ParcelCard';
import AdditionalInfoBlock from '../../components/AdditionalInfoBlock';
import PhotoDisplayList from '../../components/PhotoDisplayList';

import { useQuery } from '../../utils/useQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { useModal } from '../../context/ModalContext';

import { useSendParcel } from '../../context/SendParcelContext';
import { getUserInfo } from '../../service/user/parcel';
import { createNewRequest } from '../../service/parcel/parcel';
import DeleteRequestModal from '../../components/Modals/DeleteRequest';
import { EToast, useToast } from '../../context/ToastContext';
import { usePhotos } from '../../context/PhotosContext';
import DoneIcon from '../../assets/icons/done.svg';
import { useTranslation } from 'react-i18next';
import { ROUTE_PATHS } from '../../const/routes';
import globalRequestStore from '../../store';


const AdditionalInfoDetailPage = () => {
  const location = useLocation()
  const state = location?.state
  const query = useQuery();
  const id = query.get('id');
  const tripId = query.get('tripId');
  const {t} = useTranslation();
  const [userInfo, setUserInfo] = useState<any>()
  const {showToast} = useToast()
  const {clearPhotos} = usePhotos()
  const {
    from, to, parcelSize, dateRange,
    handleReset
  } = useSendParcel();
  const {info, handleUpdatePhoto, handleInfoChange, photos} = usePhotos()

  console.log(dateRange,'dateRange')
  useEffect(() => {
    !!id && getUserInfo(+id).then(data => setUserInfo(data))

  }, []);


  const navigate = useNavigate()

  const {openModal} = useModal();

  const handleCreateNewRequest = () => {
    const photosData = photos
      .filter(photoData => photoData.photo)
      .map(photoData => photoData.photo);
    createNewRequest({
      size:  parcelSize,
      additional_info: info,
      trip: String(tripId),
      photos: photosData

    }).then((response) => {
      handleReset()
      clearPhotos()
      navigate(ROUTE_PATHS.TRACKING)
      showToast('Your reservation is waiting for approval.', EToast.ACCEPT)
    }).catch((e) => {
      globalRequestStore.setError(JSON.stringify(e))
    })
  }

  const handleDeleteRequest = () => {
    handleReset()
    clearPhotos()
    navigate(ROUTE_PATHS.PARCEL_SEND)
  }

  const handleNavigateToUserInfo = () => navigate(`${ROUTE_PATHS.USER_INFO}?id=${id}`);
  const handleOpenRejectRequestModal = () => {
    openModal(DeleteRequestModal,
      {onDeleteRequest: handleDeleteRequest});
  };


  const ButtonsArray = [
    {
      info: 'Make sure that you have provided sufficient information and that you are familiar with the rules of transportation.',
      condition: true,
      buttons: [
        {
          color: 'fill',
          text: 'Continue',
          action: handleCreateNewRequest
        },
        {
          color: 'bezeled',
          text: 'Delete',
          action: handleOpenRejectRequestModal
        }
      ]
    },
  ]
  const getButtonsByStatus = () => {
    const currentButton = ButtonsArray.find(item => item.condition)
    return (
      <footer className={styles.footer}>
        {currentButton?.info && <p>{currentButton?.info}</p>}
        {currentButton?.buttons.map(btn => (
          <div className={styles.button}>
            <Button
              text={btn.text}
              color={btn.color}
              size="large"
              onClick={btn.action}
            />
          </div>
        ))}
      </footer>
    )
  }


  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <img src={DoneIcon} alt="DoneIcon"/>
          <h2>{t('additional_info_detail_page.title')}</h2>
          <p>
            {t('additional_info_detail_page.description')}
          </p>
        </div>
        <ParcelDetail
          imgType={EImgType.BOX}
          dateRange={{from: dateRange[0].startDate, to: dateRange[0].endDate}}
          size={parcelSize}
          place={{from: from?.title, to: to?.title}}
          // status={currentStatus}
        />
        <ParcelCard
          onClick={handleNavigateToUserInfo}
          avatarImg={userInfo?.photo_link}
          name={userInfo?.name || ''}
          rating={userInfo?.rating}
        />
        <AdditionalInfoBlock
          text={
            info
          }
        />
        <PhotoDisplayList data={photos.filter(item => item.photo)}/>


      </div>
      {getButtonsByStatus()}
    </>
  );
};
export default AdditionalInfoDetailPage;
