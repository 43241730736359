import React, { useEffect, useRef, useState } from 'react';
import { DESTINATIONS, useSendParcel } from '../../context/SendParcelContext';

import { ReactComponent as UAFlag } from '../../assets/icons/countries/uaFlag.svg';
import { ReactComponent as CZFlag } from '../../assets/icons/countries/czFlag.svg';
import { ReactComponent as GEFlag } from '../../assets/icons/countries/geFlag.svg';
import { ReactComponent as PLFlag } from '../../assets/icons/countries/plFlag.svg';
import { ReactComponent as PorFlag } from '../../assets/icons/countries/porFlag.svg';

import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/clearIcon.svg';


import styles from './LocationSelect.module.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnavailableCountryDialog from '../../components/UnavailableCountryDialog/UnavailableCountryDialog';
import { useRecentSearch } from '../../context/RecentSearchContext';
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import { axiosInstance } from '../../service/axiosConfig/axiosConfig';
import { useDeliverParcel } from '../../context/DeliverParcelContext';
import globalRequestStore from '../../store';
import { ROUTE_PATHS } from '../../const/routes';

const KEY = 'AIzaSyB4UKA-QQzRylz9ElGKAONVzsCgm8ckMYM'

const LocationSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const tgId = getHashedTgIdFromLocalStorage() || searchParams.get('hashed-td-id') as string
  const {t} = useTranslation();


  const {destination, handleFromChange, handleToChange} = useSendParcel();
  const {handleFromChange: deliverFromChange, handleToChange: deliverToChange} = useDeliverParcel()
  const {postRecentSearchResult, handleGetRecentSearch} = useRecentSearch();
  const ref = useRef(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [recentSearchResults, setRecentSearchResults] = useState<any[]>([]);

  const handleFetchRecentResults = async () => {
    const data = await handleGetRecentSearch(tgId, destination + '_' + (!!location?.state?.from ? 'send' : 'deliver'))

    if (data) {
      setRecentSearchResults(data)
    }
  }

  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const handleFetchResults = async (search: string) => {


    const tg = getProfileFromLocalStorage()
    if (!search) return null
    const url = `https://crowdsihipbc.online/api/search/autocomplete/?search=${encodeURIComponent(search)}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'tg-id': tgId,
        }
      });

      if (!response.ok) {
        globalRequestStore.setError('Network response was not ok')
      }

      const data = await response.json();

      if (data?.predictions?.length) {

        if (data.predictions.length > 0) {

          const filtered = data.predictions.map((prediction: any) => ({
            title: prediction.description,
            placeId: prediction.place_id,
            blocked: prediction.blocked
          }))
          setSearchResults(filtered);
          // const filteredResults = recentSearchResults.filter(
          //   (result) => result.placeId !== filtered[0].placeId
          // );
          // await postRecentSearchResult(tgId || searchParams.get('hashed-tg-id') as string, [{
          //   title: filtered[0].title,
          //   placeId: filtered[0].placeId
          // }, ...filteredResults.slice(0, 4)], destination + '_' + (!!location?.state?.from ? 'send' : 'deliver'))
        } else {
          setIsOpen(true);
        }
      }
    } catch (e) {
    }
  }

  const handleSearchSet = (event: any) => {

    setSearch(event.target.value)
    event.target.value.length > 2 ? handleFetchResults(event.target.value) :   setSearchResults([]);
  };

  const handleSetSearchForCountry = async (country: string) => {
    if (!country) return null;
    try {
      const data = await axiosInstance.get(`api/search/autocomplete?search=${country}`, {
        headers: {
          'tg-id': tgId,
        },
      }).then(res => res.data);

      const filteredResults = recentSearchResults.filter(
        (result) => result.placeId !== data.predictions[0].place_id
      );

      await postRecentSearchResult(
        searchParams.get('hashed-tg-id') as string,
        [
          {title: country, placeId: data.predictions[0].place_id},
          ...filteredResults.slice(0, 4)
        ],
        destination + '_' + (!!location?.state?.from ? 'send' : 'deliver')
      );


      handleDestinationSelect({
        title: country,
        placeId: data?.predictions[0]?.place_id,
        blocked: false
      });
    } catch (e: any) {
      globalRequestStore.setError(e?.message);
    }
  };

  const handleDestinationSelect = async (value: { title: string, placeId: string, blocked: boolean }) => {
    if (value.blocked) {
      setIsOpen(true)
      return
    }
    const filteredResults = recentSearchResults.filter(
      (result) => result.placeId !== value.placeId
    );
    await postRecentSearchResult(tgId || searchParams.get('hashed-tg-id') as string, [{
      title: value.title,
      placeId: value.placeId
    }, ...filteredResults.slice(0, 4)], destination + '_' + (!!location?.state?.from ? 'send' : 'deliver'))
    if (location.pathname === ROUTE_PATHS.DELIVER_SELECT_LOCATION) {
      destination === DESTINATIONS.to ? deliverToChange(value) : deliverFromChange(value);
    } else {
      destination === DESTINATIONS.to ? handleToChange(value) : handleFromChange(value);
    }
    navigate(`${location.pathname !== ROUTE_PATHS.DELIVER_SELECT_LOCATION ? ROUTE_PATHS.PARCEL_SEND : ROUTE_PATHS.PARCEL_DELIVER}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${tgId}`);
  }

  useEffect(() => {
    if (tgId) {
      handleFetchRecentResults();
    }
  }, [searchParams])


  return (
    <div className={styles.pageWrapper}>
      <div className={styles.searchBar}>
        {/*// @ts-ignore*/}
        <SearchIcon onClick={() => ref && ref?.current?.focus()} className={styles.searchIcon}/>
        <input ref={ref} className={styles.searchInput} onChange={handleSearchSet}
               placeholder={t('location_select_page.search_placeholder')} value={search} type="text"/>
        {!!search && (
          <ClearIcon onClick={() => setSearch('')} className={styles.clearIcon}/>
        )}
      </div>
      {!search && (
        <div className={styles.countriesPreSelect}>
          <div onClick={() => handleSetSearchForCountry(t('location_select_page.ukraine'))}
               className={styles.countryItem}>
            <div className={styles.avatar}>
              <UAFlag/>
            </div>
            <p>{t('location_select_page.ukraine')}</p>
          </div>
          <div onClick={() => handleSetSearchForCountry(t('location_select_page.czechia'))}
               className={styles.countryItem}>
            <div className={styles.avatar}>
              <CZFlag/>
            </div>
            <p>{t('location_select_page.czechia')}</p>
          </div>
          <div onClick={() => handleSetSearchForCountry(t('location_select_page.germany'))}
               className={styles.countryItem}>
            <div className={styles.avatar}>
              <GEFlag/>
            </div>
            <p>{t('location_select_page.germany')}</p>
          </div>
          <div onClick={() => handleSetSearchForCountry(t('location_select_page.poland'))}
               className={styles.countryItem}>
            <div className={styles.avatar}>
              <PLFlag/>
            </div>
            <p>{t('location_select_page.poland')}</p>
          </div>
          <div onClick={() => handleSetSearchForCountry(t('location_select_page.portugal'))}
               className={styles.countryItem}>
            <div className={styles.avatar}>
              <PorFlag/>
            </div>
            <p>{t('location_select_page.portugal')}</p>
          </div>
        </div>
      )}

      {recentSearchResults.length > 0 && !search && (
        <div className={styles.recentSearchBlock}>
          <h2 className={styles.subTitle}>{t('recent_search')}</h2>
          <div className={styles.searchItems}>
            {recentSearchResults.slice(0, 5).map((result) => (
              <div
                onClick={() => handleDestinationSelect({
                  title: typeof result === 'string' ? result : result.title,
                  placeId: typeof result === 'string' ? result : result.placeId,
                  blocked: result.blocked
                })}
                className={styles.result}>
                {typeof result === 'string' ? result : result.title}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!searchResults.length && (
        <div className={styles.recentSearchBlock}>
          <h2 className={styles.subTitle}>{t('location_select_page.countries_and_cities')}</h2>
          <div className={styles.searchItems}>
            {searchResults.map((result) => {

              return (
                <div onClick={() => handleDestinationSelect(result)} className={styles.result}>
                  {result.title}
                </div>
              )
            })}
          </div>
        </div>
      )}

      {isOpen && <UnavailableCountryDialog onSubmit={() => setIsOpen(false)}/>}


    </div>
  );
};

export default LocationSelect;
