import React, { useEffect, useState } from 'react';

import { ReactComponent as ParcelIcon } from '../../assets/icons/parcel.svg';

import styles from './styles.module.css';
import { useSendParcel } from '../../context/SendParcelContext';
import { Button } from '../../components/Button/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import xsParcelImage from '../../assets/images/xsParcel.png';
import sParcelImage from '../../assets/images/sParcel.png';
import mParcelImage from '../../assets/images/mParcel.png';
import lParcelImage from '../../assets/images/lParcel.png';
import { useQuery } from '../../utils/useQuery';
import { ROUTE_PATHS } from '../../const/routes';

const parcelPricesTemp = [
  {
    id: 1,
    title: 'Documents',
    description: 'per item',
    price: 5,
    value: 'Documents',
  },
  {
    id: 2,
    title: 'S',
    description: 'per item',
    price: 10,
    value: 'S',
  },
  {
    id: 3,
    title: 'M',
    description: 'per kg',
    price: 15,
    value: 'M',
  },
  {
    id: 4,
    title: 'L',
    description: 'per kg',
    price: 20,
    value: 'L',
  }
];

const TrackingSizePriceSelect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {t} = useTranslation();
  const location = useLocation()
  const query = useQuery();
  const id = query.get('id');
  const {deliverParcelSizes} = location.state;
  const selectValue = deliverParcelSizes.map(item => item.symbol)
  // const deliverParcelSizes = ["Documents", "S", "M","L"];
  const [parcelPrices, setParcelPrices] = useState<any[]>(parcelPricesTemp.map(
    item => ({
      ...item,
      price: deliverParcelSizes.find(size => size.symbol == item.value)?.price
    })
  ))

  const updatePrice = (id: number, increment: boolean) => {
    setParcelPrices((prevPrices) =>
      prevPrices.map((item) =>
        item.id === id
          ? {...item, price: Math.max(0, item.price + (increment ? 1 : -1))}
          : item
      )
    );
  };


  const onSelectClick = () => {
    const keyMap = {'Documents': 'Documents', 'S': 'S', 'M': 'M', 'L': 'L'};


    const result = Object.fromEntries(
      parcelPrices
        .filter(({ value }) => selectValue.includes(value)) // Фильтруем по массиву
        .map(({ title, price }) => [keyMap[title], price]) // Преобразуем в пары ключ-значение
    );


    navigate(
      `${ROUTE_PATHS.EDIT_INFO_PAGE}?id=${id}`, {
        state: {...location.state, deliverParcelSizes: result}
      }
    );
  };





  return (
    <div className={styles.pageWrapper}>
      <div className={styles.topWidget}>
        <ParcelIcon/>
        <h1 className={styles.title}>{t('parcel_size_price_page.title')}</h1>
        <p className={styles.subtitle}>
          {t('parcel_size_price_page.subtitle')}
        </p>
      </div>
      <div className={styles.parcelItems}>
        {parcelPrices && parcelPrices.length > 0 && parcelPrices.filter(item => selectValue.find(size => {
          return size == item.value
        })).map((item) => {

          return (
            <div key={item.id} className={styles.priceItemWrapper}>
              <p
                className={styles.priceItemTitle}>{t(`possible_earnings_page.${item.title === 'Documents' ? 'documents' : item.title === 'S' ? 'size_s' : item.title === 'M' ? 'size_m' : 'size_l'}`)}</p>
              <p
                className={styles.priceItemDescription}>{t(`parcel_size_price_page.${item.description.split(' ').join('_')}`)}</p>
              <div className={styles.controls}>
                <div onClick={() => updatePrice(item.id, false)} className={styles.control}>-</div>
                <p className={styles.price}>{item.price}$</p>
                <div onClick={() => updatePrice(item.id, true)} className={styles.control}>+</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.footer}>
        <Button color={'fill'} text={t('deliver_parcel_size_select.continue_button')} size={'large'}
                onClick={onSelectClick}/>
      </div>
    </div>
  );
};

export default TrackingSizePriceSelect;
