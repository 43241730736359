import styles from './styles.module.css'
import React, { useEffect, useRef, useState } from 'react';
import i18n from '../../i18n';
import globalRequestStore from '../../store';
import { observer } from 'mobx-react-lite';
import Arrows from '../../assets/icons/arrowsBack.svg';
import { conditionalClassNames } from '../../utils/conditionalClassNames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../const/routes';


interface IProps {
  isOpen: boolean,
  handleOpen: () => void
}

interface IPropsLanguage {
  isOpen: boolean,
  handleOpen: () => void
}

const translations = {
  'UA': 'UK',
  'UK': 'UA',
  'EN': 'EN',
  'RU': 'RU',
};
const Settings: React.FC<IProps> = observer((props) => {
  const {t} = useTranslation()
  const [selectLanguage, setSelectLanguage] = useState(false);
  const navigate = useNavigate()
  const handleLanguageSelect = () => setSelectLanguage(prevState => !prevState);
  useEffect(() => {
    if (!globalRequestStore.settingsIsOpen) {
      setSelectLanguage(false)
    }

  }, [globalRequestStore.settingsIsOpen]);
  const some = {
    'settings': {
      'privacy_policy': 'Privacy policy',
      'terms_of_use': 'Terms of use',
      'language': 'Language'
    }
  }

  const settingsItems = [
    {
      text: t('settings.privacy_policy'),
      onClick: () => {
        navigate(ROUTE_PATHS.PRIVACY_POLICY_PAGE)
      },
    },
    {
      text: t('settings.terms_of_use'),
      onClick: () => {
        navigate(ROUTE_PATHS.TERMS_OF_USE_PAGE)
      },
    },
    {
      text: t('settings.language'),
      onClick: handleLanguageSelect,
      right: translations[i18n.language.toUpperCase()],
    },
  ];

  if (!globalRequestStore.settingsIsOpen) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {settingsItems.map(item => (
          <div className={styles.item} onClick={item.onClick} key={item.text}>
            <p>{item.text}</p>
            {item.right && <p>{item.right}<img src={Arrows} alt=""/></p>}
          </div>
        ))}
      </div>
      <LanguageSelector isOpen={selectLanguage} handleOpen={handleLanguageSelect}/>
    </div>
  );
});

const LanguageSelector: React.FC<IPropsLanguage> = (props) => {
  if (!props.isOpen) return null
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    props.handleOpen()

  };

  const LanguageItems = [
    {
      text: 'UA',
      onClick: () => changeLanguage('uk'),
    },
    {
      text: 'EN',
      onClick: () => changeLanguage('en'),
    },
    {
      text: 'RU',
      onClick: () => changeLanguage('ru'),
    },
  ]

  return (
    <div className={styles.languageWrapper}>
      <div className={styles.languageContent}>
        {LanguageItems.map(item => (
          <div
            className={conditionalClassNames(styles.item, translations[i18n.language.toUpperCase()] === item.text && styles.activeItem)}
            onClick={item.onClick}>
            <p>{item.text} </p>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Settings
