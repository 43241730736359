import React, { useEffect } from 'react';

import styles from './DeliverProvideMoreInfoPage.module.css';
import { Button } from '../../components/Button/Button';

import { ReactComponent as ParcelIcon } from '../../assets/icons/parcel.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSendParcel } from '../../context/SendParcelContext';
import { useTranslation } from 'react-i18next';
import { useDeliverParcel } from '../../context/DeliverParcelContext';
import TextArea from '../../components/TextArea';
import { ROUTE_PATHS } from '../../const/routes';


const DeliverProvideMoreInfoPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {t} = useTranslation();
  const {additionalInfo, handleAdditionalInfoChange} = useDeliverParcel();

  const onSelectClick = () => {
    if (additionalInfo.length) {
      navigate(
        `${ROUTE_PATHS.CHECK_YOUR_DELIVER}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${searchParams.get('hashed-tg-id')}`
      );
    }
  };


  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
  };
  return (
    <div className={styles.globalWrapper}>
      <div className={styles.pageWrapper}>
        <div className={styles.topWidget}>
          <ParcelIcon/>
          <h1 className={styles.title}>{t('deliver_provide_more_info_page.title')}</h1>
          <p className={styles.subtitle}>
            {t('deliver_provide_more_info_page.subtitle')}
          </p>
        </div>

        <div className={styles.recentSearchBlock}>
          <h2 className={styles.subTitle}>{t('deliver_provide_more_info_page.additional_info_label')}</h2>
          <TextArea value={additionalInfo}
                    onChange={handleAdditionalInfoChange} name="additional_info"
                    id="usr-additional_info"
                    placeholder={t('deliver_provide_more_info_page.textarea_placeholder')}></TextArea>
        </div>
      </div>
      <div className={styles.bottomBlock}>
        <Button color="fill" text={t('deliver_provide_more_info_page.continue_button')} size="large"
                onClick={onSelectClick} disabled={!additionalInfo.length}/>
      </div>
    </div>

  );
};

export default DeliverProvideMoreInfoPage;
