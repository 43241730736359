import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getUserInfo } from '../../service/user/parcel';
import Avatar, { AvatarSizes } from '../../components/Avatar/Avatar';

import { ReactComponent as SendParcelIcon } from '../../assets/icons/sendParcelIcon.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/starSmall.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrowRight.svg';

import styles from './SendParcelSingleResultPage.module.css';
import { Button } from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';

import { ROUTE_PATHS } from '../../const/routes';

const SendParcelSingleResultPage: FC = () => {
  const {id} = useParams();
  const navigate = useNavigate();


  const [searchParams] = useSearchParams();
  const destination = searchParams.get('destination');
  const details = searchParams.get('details');
  const size = searchParams.get('size');
  const price = searchParams.get('price');
  const dates = searchParams.get('dates');
  const tripId = searchParams.get('tripId')

  const {t} = useTranslation();

  const [courierData, setCourierData] = useState<any>(null);



  useEffect(() => {

    !!id && getUserInfo(+id).then(data => setCourierData(data))
  }, [id]);



  const handleCourierSelect = () => {
    navigate(`/parcel-send/courier/${id}?hashed-tg-id=${searchParams.get('hashed-tg-id')}&`);
  }

  const onHandleNavigateToAdditionalPage = () => {
    navigate(`${ROUTE_PATHS.ADDITIONAL_INFO}?id=${id}&tripId=${tripId}`)
  }
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        {destination && dates && size && (
          <div className={styles.topWidget}>
            <SendParcelIcon/>
            <h1 className={styles.title}>
              {destination}
            </h1>
            <p className={styles.subTitle}>{dates}</p>
            <span className={styles.parcelSize}>{size}</span>
            <div className={styles.bottomPrice}>
              <p className={styles.text}>{['M','L'].includes(size) ? "Price per kg" :  "Price per item"}</p>
              <p className={styles.price}>{price}$</p>
            </div>
          </div>
        )}

        <div onClick={handleCourierSelect} className={styles.courierWrapper}>
          <Avatar avatarUrl={courierData ? courierData.avatarUrl : ''} size={AvatarSizes.s}
                  profileName={courierData ? courierData.name : 'P'}/>
          <div className={styles.courierInfo}>
            <p className={styles.name}>{courierData ? courierData.name : 'P'}</p>
            <div className={styles.ratingWrapper}>
              {courierData?.rating && <span><StarIcon/>{courierData ? courierData.rating : '0.0'}</span>}
              <ArrowRightIcon/>
            </div>
          </div>
        </div>

        <div className={styles.detailsWrapper}>
          <span>{t('send_parcel_courier_page.details')}</span>
          <p className={styles.detailsContent}>{searchParams.get('details')}</p>
        </div>

      </div>
      <div className={styles.bottomBlock}>
        <Button color="fill" text={t('send_parcel_courier_page.create_request_button')} size="large"
                onClick={onHandleNavigateToAdditionalPage}/>
      </div>
    </div>
  );
};

export default SendParcelSingleResultPage;
