import { axiosInstance } from '../axiosConfig/axiosConfig';
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import globalRequestStore from '../../store';

export interface Courier {
  id: number;
  name: string;
  photo_link: string;
  rating: string; // Можно использовать number, если рейтинг всегда будет числом
}

export interface Parcel {
  id: number;
  courier: Courier;
  departure_point: string;
  destination_point: string;
  dispatch_period_start: string;
  dispatch_period_end: string;
  status: string;
  price: number
}

export interface ParcelInfo extends Parcel {
  photos: string[];
  size: string;
  additional_info: string;
  price_detail: {
    key: string,
    value: number
  }
}

export interface GetActiveParcelsResponse {
  parcels?: Parcel[];
}

export interface Trip {
  id: number;
  requests: {
    approved: number;
    requested: number;
  };
  departure_point: string;
  destination_point: string;
  dispatch_period_start: string;
  dispatch_period_end: string;
  // TODO temp
  state: any;
}



export async function getActiveParcels(): Promise<{ results: Parcel[] } | any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/delivery_requests/active-parcels/`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export async function getArchiveParcels(): Promise<{ results: Parcel[] } | any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/delivery_requests/archive-parcels/`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}


export async function getActiveTrips(): Promise<{ results: Trip[] } | any> {
  const tgId = getHashedTgIdFromLocalStorage();

  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/trips/trips/?type=courier`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export async function getArchivedTrips(): Promise<{ results: Trip[] } | any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/trips/trips/?type=archived`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export async function getActiveParcelById(id: number): Promise<ParcelInfo | any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/delivery_requests/active-parcels/${id}`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export async function deleteActiveParcelById(id: number): Promise<any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.delete(
      `/api/delivery_requests/active-parcels/${id}`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export interface Sender {
  id: number;
  name: string;
  rating: number;
  photo_link?: string;
}

interface Request {
  sender: Sender;
  id: number;
  departure_point: string;
  destination_point: string;
  status: string;
  photo_link?: string;
  size: string;
}

export interface Size {
  id: number;
  name: string;
  symbol: string;
}

export interface DeliveryDetail {
  id: number;
  courier: Courier;
  requests: Request[];
  departure_point: string;
  destination_point: string;
  dispatch_period_start: string;
  dispatch_period_end: string;
  sizes_available: Size[];
  additional_info: string;
  is_active: boolean;
}

export async function getActiveTripById(id: number): Promise<DeliveryDetail | any> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.get(
      `/api/trips/trips/${id}/`,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();
    return response.data;
  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export enum EParcelRequestStatus {
  Approved = 'Approved',
  Disapproved = 'Disapproved',
  Delivered = 'Delivered'
}

interface UpdateParcelStatusParams {
  status: EParcelRequestStatus;
  id: number;
}

export async function updateParcelStatus({ status, id }: UpdateParcelStatusParams): Promise<void> {
  const tgId = getHashedTgIdFromLocalStorage();
  const data: any = { status };

  if (status === EParcelRequestStatus.Delivered) {
    data.state = 'History';
  }

  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.patch(
      `/api/delivery_requests/active-parcels/${id}/`,
      data,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();

  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}

export async function patchTrip({ updatedDetails, id }: { updatedDetails: Partial<DeliveryDetail>, id: number }): Promise<void> {
  const tgId = getHashedTgIdFromLocalStorage();
  try {
    globalRequestStore.startLoading();
    const response = await axiosInstance.patch(
      `/api/trips/trips/${id}/`,
      updatedDetails,
      {
        headers: {
          'tg-id': tgId
        }
      }
    );
    globalRequestStore.finishLoading();

  } catch (error:any) {
    globalRequestStore.setError(error.message);
  }
}
