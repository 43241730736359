import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as ParcelIcon } from '../../assets/icons/parcel.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/infoIcon.svg';

import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrowRight.svg';


import { DESTINATIONS, useSendParcel } from '../../context/SendParcelContext';
import { formatDate, formatFullDate } from '../../utils/dateUtil';
import { Button } from '../../components/Button/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';

import styles from './CheckYourDeliveryPage.module.css';
import moment from 'moment';
import Dialog from '../../components/Dialog/Dialog';

import { useTranslation } from 'react-i18next';

import style from '../EditProfile/editProfile.module.css';
import { useDeliverParcel } from '../../context/DeliverParcelContext';
import { useRecentSearch } from '../../context/RecentSearchContext';
import { useModal } from '../../context/ModalContext';
import LocationSelectModal from '../../components/Modals/LocationSelectModal';
import DateRangeSelectModal from '../../components/Modals/DateRangeSelecModal';
import TextArea from '../../components/TextArea';
import { ROUTE_PATHS } from '../../const/routes';
import { getHashedTgIdFromLocalStorage } from '../../utils/storeActions';

const CheckYourDeliveryPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const tgId = getHashedTgIdFromLocalStorage()
  const {handlePostTrip, parcelPrices} = useDeliverParcel();
  const {postRecentSearchResult, handleGetRecentSearch} = useRecentSearch()

  const {
    deliverParcelSizes,
    additionalInfo: additionalInfoOld,
    dateRange,
    parcelSize,
    from,
    to,
    handleDestinationChange,
    handleFromChange,
    handleToChange,
    handleAdditionalInfoChange,
    handleDateRangeChange,
    handleDeliverParcelSizesChange
  } = useDeliverParcel();
  const [modalIOpen, setModalOpen] = useState(false);

  // const handleNavigateToSelectLocationPage = () => {
  //     navigate(`${ROUTE_PATHS.SELECT_LOCATION}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${tgId}`)
  // }
  const [additionalInfo, setAdditionalInfo] = useState(additionalInfoOld || '')
  const additionalInfoRef = useRef<HTMLTextAreaElement>(null);
  const onEditClick = () => {
    navigate(`${ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT_EDIT}?goBack=true&tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${tgId}`)
  }
  const handleFocus = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) {
          window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth',
          });
        }
      }, 1000);
    }
  };
  // const handleNavigateToParcelDeliver = () => {
  //     navigate(`${ROUTE_PATHS.PARCEL_DELIVER}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${tgId}`)
  // }
  //
  // const onToDestinationClick = () => {
  //     handleDestinationChange(DESTINATIONS.to)
  //     handleNavigateToSelectLocationPage();
  // }
  //
  // const onFromDestinationClick = () => {
  //     handleDestinationChange(DESTINATIONS.from)
  //     handleNavigateToSelectLocationPage()
  // }

  const handleDeleteRequest = () => {
    navigate(ROUTE_PATHS.PARCEL_DELIVER);
    handleFromChange({title: '', placeId: ''});
    handleToChange({title: '', placeId: ''});
    handleAdditionalInfoChange('');
    handleDateRangeChange(undefined);
    handleDeliverParcelSizesChange([]);
  }
  const location = useLocation()
  const {openModal} = useModal()
  // const handleChangeLocation = (isTo: boolean) => {
  //   // navigate(ROUTE_PATHS.PLUG)
  //   openModal(LocationSelectModal, {
  //     onSelect: isTo ? handleToChange : handleFromChange
  //   })
  // }
  const onChangeDateRange = (startDate: string, endDate: string) => {
    handleDateRangeChange([{
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
      key: 'selection'
    }])
  }
  useEffect(() => {
    if (location?.state?.newDestination) {
      location.state?.isTo ? handleToChange(location?.state?.newDestination) : handleFromChange(location?.state?.newDestination)
    }
  }, [location?.state?.newDestination]);
  useEffect(() => {
    if (location?.state?.newDateRange) {
      onChangeDateRange(location?.state?.newDateRange.startDate, location?.state?.newDateRange.endDate)
    }
  }, [location?.state?.newDateRange]);
  const handleChangeLocation = (isTo: boolean) => {
    console.log(from,
      to)
    navigate(ROUTE_PATHS.CUSTOM_SELECT_LOCATION + '?id=0', {
      state: {
        isTo,
      }
    })
  }


  const handleChangeDateRange = () => {
    navigate(ROUTE_PATHS.CUSTOM_DATE_RANGE_SELECT + '?id=0', {
      state: {...location.state, dateRange}

    })
    // openModal(DateRangeSelectModal, {
    //   onChange: onChangeDateRange,
    //   dateRange: {startDate: moment(dateRange[0].startDate).toDate(), endDate: moment(dateRange[0].endDate).toDate()}
    // })
  }
  const onPossibleEarningsClick = () => {
    navigate(`${ROUTE_PATHS.POSSIBLE_EARNINGS}?hashed-tg-id=${getHashedTgIdFromLocalStorage()}`);
  }

  const onPublishClick = async () => {
    const sizes = parcelPrices.filter(item => deliverParcelSizes.find(size => size === item.title))
    const newDeliverParcelSizes = sizes.reduce((acc, item) => {
      switch (item.title) {
        case 'S':
          acc['S'] = item.price;
          break;
        case 'M (up to 5 kg)':
          acc['M'] = item.price;
          break;
        case 'L (up to 20 kg)':
          acc['L'] = item.price;
          break;
        default:
          acc['Documents'] = item.price;
      }
      return acc;
    }, {} as any);
    const data = {
      'departure_point': from.placeId,
      'destination_point': to.placeId,
      'dispatch_period_start': moment(dateRange[0].startDate).format('YYYY-MM-DD'),
      'dispatch_period_end': moment(dateRange[0].endDate).format('YYYY-MM-DD'),
      'additional_info': additionalInfo,
      'is_active': true,
      'sizes_available': newDeliverParcelSizes,
      'courier': parseFloat(searchParams.get('tg-id') as string),

    }

    const recentResults = await handleGetRecentSearch(tgId as string, 'send') || []

    await postRecentSearchResult(tgId as string, [{
      from,
      to,
      deliverParcelSizes,
      startDate: JSON.stringify(dateRange[0]?.startDate),
      endDate: JSON.stringify(dateRange[0]?.endDate)
    }, ...recentResults], 'deliver')
    await handlePostTrip(tgId as string, data)
    navigate(`${ROUTE_PATHS.TRACKING}?hashed-tg-id=${tgId}`);

    handleFromChange({title: '', placeId: ''});
    handleToChange({title: '', placeId: ''});
    handleAdditionalInfoChange('');
    handleDateRangeChange(undefined);
    handleDeliverParcelSizesChange([]);
  }


  const handleChangeAdditionInfo = (value) => {
    setAdditionalInfo(value)
    handleAdditionalInfoChange(value)
  }
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.topWidget}>
        <ParcelIcon/>
        <h1 className={styles.title}>{t('check_your_delivery_page.title')}</h1>
        <p className={styles.subtitle}>
          {t('check_your_delivery_page.subtitle')}
        </p>
      </div>

      <h2 className={styles.subTitle}>{t('check_your_delivery_page.destination_and_dates')}</h2>
      <div className={styles.destinationWidget}>

        <div className={styles.destination} onClick={() => handleChangeLocation(false)}>
          <span>{from.title || t('check_your_delivery_page.from')}</span>
          <ArrowRightIcon/>
        </div>
        <div className={styles.destination} onClick={() => handleChangeLocation(true)}>
          <span>{to.title || t('check_your_delivery_page.to')}</span>
          <ArrowRightIcon/>
        </div>
        <div className={styles.destination} onClick={handleChangeDateRange}>
          <span>{dateRange ? `${formatDate(dateRange[0].startDate)} - ${formatDate(dateRange[0].endDate)}` : t('check_your_delivery_page.dates')}</span>
          <ArrowRightIcon/>
        </div>
      </div>

      <h2 className={styles.subTitle}>{t('check_your_delivery_page.size')}</h2>
      <div className={styles.sizeWidget}>
        {parcelPrices.filter(item => deliverParcelSizes.find(size => size === item.title)).map(item => (
          <div key={item.id} className={styles.size}>
            <span>{item.title}</span>
            <span
              className={styles.priceForSize}>{item.description === 'per kg' && item.title.includes('L') ? item.price * 20 : item.description === 'per kg' && item.title.includes('M') ? item.price * 5 : item.price}$</span>
          </div>
        ))}
        <div className={styles.sizeWidgetBottom}>
          <p className={styles.widgetText}>{t('check_your_delivery_page.possible_earnings')}<InfoIcon
            onClick={onPossibleEarningsClick}/></p>
          <p
            className={styles.totalPrice}>≈ {parcelPrices.filter(item => deliverParcelSizes.find(size => size === item.title)).reduce((previousValue, currentValue) => {
            return currentValue.description === 'per kg' && currentValue.title.includes('L') ? currentValue.price * 20 + previousValue : currentValue.description === 'per kg' && currentValue.title.includes('M') ? currentValue.price * 5 + previousValue : currentValue.price + previousValue;
          }, 0)}$</p>
        </div>
      </div>
      <p className={styles.subtext}>{t('check_your_delivery_page.edit_text')} <span
        onClick={onEditClick}>{t('check_your_delivery_page.edit')}</span></p>

      <div className={styles.recentSearchBlock}>
        <h2 className={styles.subTitle}>{t('check_your_delivery_page.additional_info')}</h2>
        <TextArea
          onFocus={() => handleFocus(additionalInfoRef)}
          value={additionalInfo || ''}
          onChange={handleChangeAdditionInfo}

          className={style.textArea}
          placeholder={t('additional_info_page.placeholder')}
        ></TextArea>
        {/*<div className={styles.textArea}>{additionalInfo}</div>*/}
      </div>

      <div className={styles.bottomBlock}>
        <Button text={t('check_your_delivery_page.publish_button')} size={'large'} color={'fill'}
                onClick={onPublishClick}/>
        <Button text={t('check_your_delivery_page.delete_button')} size={'large'} color={'gray'}
                onClick={() => setModalOpen(true)}/>
      </div>
      {modalIOpen && <Dialog
          onSubmit={handleDeleteRequest}
          onCancel={() => setModalOpen(false)}
          title={t('check_your_delivery_page.delete_dialog_title')}
          additionalText={t('check_your_delivery_page.delete_dialog_text')}
      />}
    </div>
  );
};

export default CheckYourDeliveryPage;
