import React, {useEffect} from 'react';

import xsParcelImage from '../../assets/images/xsParcel.png';
import sParcelImage from '../../assets/images/sParcel.png';
import mParcelImage from '../../assets/images/mParcel.png';
import lParcelImage from '../../assets/images/lParcel.png';

import styles from "./ParcelSizeSelect.module.css";
import {useSendParcel} from "../../context/SendParcelContext";
import {Button} from "../../components/Button/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { getHashedTgIdFromLocalStorage, getProfileFromLocalStorage } from '../../utils/storeActions';
import { ROUTE_PATHS } from '../../const/routes';

const ParcelSizeSelect = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { t } = useTranslation();

    const PARCEL_SIZES_CONFIG = [
        {
            id: 1,
            name: t('parcel_size_select_page.documents'),
            description: "",
            image: xsParcelImage,
            value: 'Documents',
        },
        {
            id: 2,
            name: t('parcel_size_select_page.s_size.title'),
            description: t('parcel_size_select_page.s_size.description'),
            image: sParcelImage,
            value: 'S',
        },
        {
            id: 3,
            name: t('parcel_size_select_page.m_size.title'),
            description: t('parcel_size_select_page.m_size.description'),
            image: mParcelImage,
            value: 'M',
        },
        {
            id: 4,
            name: t('parcel_size_select_page.l_size.title'),
            description: t('parcel_size_select_page.l_size.description'),
            image: lParcelImage,
            value: 'L',
        },
    ]

    const { parcelSize, handleParcelSizeChange } = useSendParcel();

    const onChange = (event: any) => handleParcelSizeChange(event.target.value);

    const onSelectClick = () => {
        const localStoreTgId = getHashedTgIdFromLocalStorage()
        if (parcelSize) {
            navigate(`${ROUTE_PATHS.PARCEL_SEND}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${localStoreTgId || searchParams.get('hashed-tg-id')}`);

        }
    }



    return (
        <div className={styles.pageWrapper}>
            <div className={styles.parcelItems}>
                {PARCEL_SIZES_CONFIG.map(item => (
                    <label key={item.id} htmlFor={item.value} className={`${styles.parcelSizeItem} ${parcelSize === item.value ? styles.active : ""}`}>
                        <div className={styles.parcelItem}>
                            <img src={item.image} alt={item.name}  height={100} width={100}/>
                            <div className={styles.parcelInfo}>
                                <p>{item.name}</p>
                                <p>{item.description}</p>
                                <div className={`${styles.inputWrapper} ${parcelSize === item.value ? styles.active : ""}`}>
                                    <input id={item.value} onChange={onChange} checked={parcelSize === item.value} value={item.value} type="radio" name={"size"}/>
                                </div>
                            </div>
                        </div>
                    </label>
                ))}
            </div>
            <div className={styles.footer}>
                <Button color={"fill"} text={t('parcel_size_select_page.select_button')} size={"large"} onClick={onSelectClick} disabled={!parcelSize} />
            </div>
        </div>
    );
};

export default ParcelSizeSelect;
