import React, {useEffect, useState} from 'react';

import xsParcelImage from '../../assets/images/xsParcel.png';
import sParcelImage from '../../assets/images/sParcel.png';
import mParcelImage from '../../assets/images/mParcel.png';
import lParcelImage from '../../assets/images/lParcel.png';

import { ReactComponent as ParcelIcon } from "../../assets/icons/parcel.svg";

import styles from './DeliverParcelSizeSelect.module.css';
import { Button } from '../../components/Button/Button';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useDeliverParcel} from "../../context/DeliverParcelContext";
import { ROUTE_PATHS } from '../../const/routes';

export const PARCEL_SIZES_CONFIG = [
    {
        id: 1,
        name: 'Documents',
        description: '',
        image: xsParcelImage,
        value: 'Documents',
    },
    {
        id: 2,
        name: 'S',
        description: '',
        image: sParcelImage,
        value: 'S',
    },
    {
        id: 3,
        name: 'M',
        description: 'up to 5 kg',
        image: mParcelImage,
        value: 'M',
    },
    {
        id: 4,
        name: 'L',
        description: 'up to 20 kg',
        image: lParcelImage,
        value: 'L',
    },
];

const DeliverParcelSizeSelect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const { t } = useTranslation();

    const PARCEL_SIZES_CONFIG = [
        {
            id: 1,
            name: t('parcel_size_select_page.documents'),
            description: "",
            image: xsParcelImage,
            value: 'Documents',
        },
        {
            id: 2,
            name: t('parcel_size_select_page.s_size.title'),
            description: t('parcel_size_select_page.s_size.description'),
            image: sParcelImage,
            value: 'S',
        },
        {
            id: 3,
            name: t('parcel_size_select_page.m_size.title'),
            description: t('parcel_size_select_page.m_size.description'),
            image: mParcelImage,
            value: 'M (up to 5 kg)',
        },
        {
            id: 4,
            name: t('parcel_size_select_page.l_size.title'),
            description: t('parcel_size_select_page.l_size.description'),
            image: lParcelImage,
            value: 'L (up to 20 kg)',
        },
    ]

    const { deliverParcelSizes, handleDeliverParcelSizesChange } = useDeliverParcel();

    const [selectedValues, setSelectedValues] = useState<string[]>(deliverParcelSizes);

    const onChange = (event: any) => {
        const value = event.target.value;

        setSelectedValues((prevState) => {
            const newSelectedValues = [...prevState];

            const selectedIndex = PARCEL_SIZES_CONFIG.findIndex(item => item.value === value);

            if (!newSelectedValues.includes(value)) {
                newSelectedValues.push(value);
                for (let i = 0; i < selectedIndex; i++) {
                    const previousValue = PARCEL_SIZES_CONFIG[i].value;
                    if (!newSelectedValues.includes(previousValue)) {
                        newSelectedValues.push(previousValue);
                    }
                }
            } else {
                newSelectedValues.splice(newSelectedValues.indexOf(value), 1);
            }

            return newSelectedValues;
        });
    };

    const onSelectClick = () => {
        if (selectedValues.length) {
            navigate(
                `${location.pathname === ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT ? ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT : ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT_EDIT}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${searchParams.get('hashed-tg-id')}`
            ,{state: {sizes: selectedValues}});
        }
    };

    useEffect(() => {
        // handleDeliverParcelSizesChange(selectedValues);
    }, [selectedValues]);



    return (
        <div className={styles.globalWrapper}>
            <div className={styles.pageWrapper}>
                <div className={styles.topWidget}>
                    <ParcelIcon />
                    <h1 className={styles.title}>{t('deliver_parcel_size_select.title')}</h1>
                    <p className={styles.subtitle}>
                        {t('deliver_parcel_size_select.subtitle')}
                    </p>
                </div>
                <div className={styles.parcelItems}>
                    {PARCEL_SIZES_CONFIG.map((item) => (
                        <label
                            key={item.id}
                            htmlFor={item.value}
                            className={`${styles.parcelSizeItem} ${selectedValues.includes(item.value) ? styles.active : ''}`}
                        >
                            <div className={styles.parcelItem}>
                                <img src={item.image} alt={item.name} height={100} width={100}/>
                                <div className={styles.parcelInfo}>
                                    <p>{item.name}</p>
                                    <p>{item.description}</p>
                                    <div className={`${styles.inputWrapper} ${selectedValues.includes(item.value) ? styles.active : ''}`}>
                                        <input
                                            id={item.value}
                                            onChange={onChange}
                                            checked={selectedValues.includes(item.value)}
                                            value={item.value}
                                            type="checkbox"
                                            name="size"
                                        />
                                    </div>
                                </div>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            <div className={styles.bottomBlock}>
                <Button color="fill" text={t('deliver_parcel_size_select.continue_button')} size="large" onClick={onSelectClick} disabled={selectedValues.length === 0} />
            </div>
        </div>
    );
};

export default DeliverParcelSizeSelect;
