import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import ParcelDetail, { EImgType } from '../../components/ParcelDetail';
import { mapParcelStatus } from '../../components/ParcelCard/ParcelStatus';
import ParcelCard from '../../components/ParcelCard';
import boxEmoticon from '../../assets/icons/boxEmoticon.svg';
import { DeliveryDetail, getActiveTripById, patchTrip, Sender } from '../../service/tracking/tracking';
import { useQuery } from '../../utils/useQuery';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { useModal } from '../../context/ModalContext';
import TripDetails from '../../components/TripDetails';
import { useTranslation } from 'react-i18next';
import CloseOrdersModal from '../../components/Modals/CloseOrders';
import { ROUTE_PATHS } from '../../const/routes';

const CourierDeliverDetailPage = () => {
  const query = useQuery();
  const id = query.get('id');

  const {t} = useTranslation();
  const {openModal} = useModal()
  const [reset, setReset] = useState(false);
  const [approved, setApproved] = useState<any>();
  const [requested, setRequested] = useState<any>();
  const [deliveryDetail, setDeliveryDetail] = useState<any>();

  useEffect(() => {
    id && getActiveTripById(+id).then(data => {
      setApproved(data?.requests?.filter(item => item.status !== 'Requested'));
      setRequested(data?.requests?.filter(item => item.status === 'Requested'));
      setDeliveryDetail(data);
    });
  }, [reset]);

  const navigate = useNavigate();



  if (!deliveryDetail) return null;

  const handleNavigateToSenderInfo = (id: number, sender: Sender) =>
    navigate(`${ROUTE_PATHS.SENDER_DELIVER_DETAIL_PAGE}?id=${id}`, {state: sender});

  const handleNavigateToEditInfo = () =>
    navigate(`${ROUTE_PATHS.EDIT_INFO_PAGE}?id=${id}`, {
      state: {
        deliverParcelSizes: deliveryDetail.sizes,
        additionalInfo: deliveryDetail.additional_info,
        dateRange: {startDate: deliveryDetail.dispatch_period_start, endDate: deliveryDetail.dispatch_period_end},
        from: deliveryDetail.departure_point,
        to: deliveryDetail.destination_point,
      },
    });
  const handleCloseOrders = () => {
    id && patchTrip({
      updatedDetails: {is_active: false}, id: +id
    }).then(() => {

      navigate(ROUTE_PATHS.TRACKING);
      // setReset(prevState => !prevState)
    })
  }
  const onHandleClickCloseOrder = () => {
    openModal(CloseOrdersModal, {
      onCancelOrder: handleCloseOrders
    })
  }


  const ButtonsArray = [
    {
      condition: !approved?.length && !requested?.length && deliveryDetail.is_active,
      buttons: [
        {
          color: 'fill',
          text: t('courier_deliver_detail_page.edit_button'),
          action: handleNavigateToEditInfo,
        },
        {
          color: 'bezeled',
          text: t('courier_deliver_detail_page.close_orders_button'),
          action: onHandleClickCloseOrder,
        },
      ],
    },
    {
      condition: !approved?.length && !requested?.length && !deliveryDetail.is_active,
      buttons: [
        {
          color: 'fill',
          text: t('courier_deliver_detail_page.edit_button'),
          action: handleNavigateToEditInfo,
        },
      ],
    },
    {
      condition: deliveryDetail.is_active,
      buttons: [
        {
          color: 'bezeled',
          text: t('courier_deliver_detail_page.close_orders_button'),
          action: onHandleClickCloseOrder,
        },
      ],
    },
  ];

  const getButtonsByStatus = () => {
    const currentButton = ButtonsArray.find(item => item.condition);
    return (
      <footer className={styles.footer}>
        {currentButton?.buttons.map((btn, index) => (
          <div key={index} className={styles.button}>
            <Button
              text={btn.text}
              color={btn.color}
              size="large"
              onClick={btn.action}
            />
          </div>
        ))}
      </footer>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <ParcelDetail
          imgType={EImgType.BUS}
          dateRange={{from: deliveryDetail.dispatch_period_start, to: deliveryDetail.dispatch_period_end}}
          place={{from: deliveryDetail.departure_point, to: deliveryDetail.destination_point}}
        />
        <TripDetails additionalInfo={deliveryDetail.additional_info} sizes={deliveryDetail.sizes}/>

        {approved?.length > 0 && (
          <>
            <p className={styles.requestsLabel}>{t('courier_deliver_detail_page.approved_parcels_label')}</p>
            {approved.map(item => (
              <ParcelCard
                key={item.id}
                avatarImg={item.sender.photo_link}
                place={{from: item.departure_point, to: item.destination_point}}
                size={item.size}
                onClick={() => handleNavigateToSenderInfo(item.id, item.sender)}
                name={item.sender.name}
                // status={mapParcelStatus(item.status)}
                rating={item.sender.rating}
              />
            )).reverse()}
          </>
        )}

        {requested?.length > 0 && (
          <>
            <p
              className={styles.requestsLabel}>{t('courier_deliver_detail_page.requests_label')} ({requested.length})</p>
            {requested.map(item => (
              <ParcelCard
                key={item.id}
                avatarImg={item.sender.photo_link}
                place={{from: item.departure_point, to: item.destination_point}}
                size={item.size}
                onClick={() => handleNavigateToSenderInfo(item.id, item.sender)}
                name={item.sender.name}
                // status={mapParcelStatus(item.status)}
                rating={item.sender.rating}
              />
            )).reverse()}
          </>
        )}

        {!(requested?.length || approved?.length) && (
          <>
            <p className={styles.requestsLabel}>{t('courier_deliver_detail_page.requests_label')}</p>
            <div className={styles.noRequests}>
              <img alt="boxEmoticon" height={24} width={24} src={boxEmoticon}/>
              <p>{t('courier_deliver_detail_page.no_requests_message')}</p>
            </div>
          </>
        )}
      </div>
      {getButtonsByStatus()}
    </>
  );
};

export default CourierDeliverDetailPage;
