import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { tg } from '../../App';
import { ROUTE_PATHS } from '../../const/routes';
import { useModal } from '../../context/ModalContext';
import globalRequestStore from '../../store';
import { saveHashedTgIdToLocalStorage } from '../../utils/storeActions';

const tgBackButtonParams: any = {
  hide: [
    ROUTE_PATHS.HOME,
    ROUTE_PATHS.PROFILE,
    ROUTE_PATHS.PARCEL_SEND,
    ROUTE_PATHS.TRACKING,
    ROUTE_PATHS.PARCEL_DELIVER,
  ],
  show: [
    {pathName: ROUTE_PATHS.HOME, condition: null},
    {pathName: ROUTE_PATHS.PROFILE, condition: null},
    {pathName: ROUTE_PATHS.USER_INFO, condition: null},
    {pathName: ROUTE_PATHS.EDIT_PROFILE, condition: null},
    {pathName: ROUTE_PATHS.PHOTO_PAGE, condition: null},
    {pathName: ROUTE_PATHS.ADDITIONAL_INFO, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND, condition: null},
    {pathName: ROUTE_PATHS.SELECT_DATE_RANGE, condition: null},
    {pathName: ROUTE_PATHS.SELECT_LOCATION, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SIZE_SELECT, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULTS, goBack: ROUTE_PATHS.PARCEL_SEND, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULT, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_SEND_SEARCH_RESULT_COURIER, condition: null},
    {pathName: ROUTE_PATHS.TRACKING, condition: null},
    {pathName: ROUTE_PATHS.TRACKING_ARCHIVED, condition: null},
    {pathName: ROUTE_PATHS.SENDER_DELIVER_DETAIL_PAGE, condition: null},
    {pathName: ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE, goBack: ROUTE_PATHS.TRACKING, condition: null},
    {pathName: ROUTE_PATHS.GIVE_FEEDBACK_PAGE, condition: null},
    {pathName: ROUTE_PATHS.EDIT_INFO_PAGE, goBack: ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE, condition: null},
    {pathName: ROUTE_PATHS.TRACKING_SIZE_SELECT, condition: null},
    {pathName: ROUTE_PATHS.ADDITIONAL_INFO_DETAIL_PAGE, condition: null},
    {pathName: ROUTE_PATHS.TRACKING_SIZE_PRICE_SELECT, condition: null},
    {pathName: ROUTE_PATHS.PARCEL_DELIVER, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_SELECT_LOCATION, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_DATE_RANGE, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT_EDIT, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PARCEL_PRICE_SELECT_EDIT, condition: null},
    {pathName: ROUTE_PATHS.DELIVER_PROVIDE_MORE_INFO, condition: null},
    {pathName: ROUTE_PATHS.CHECK_YOUR_DELIVER, condition: null},
    {pathName: ROUTE_PATHS.POSSIBLE_EARNINGS, condition: null},
  ],
};
const PathListener: React.FC = () => {
  const [searchParams] = useSearchParams();
  const tgId = searchParams.get('hashed-tg-id');
  const tgBtn = tg.BackButton;
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = useRef<string | null>(null);
  const [history, setHistory] = useState<{ path: string; state: any }[]>(() => {
    // Загружаем историю из sessionStorage
    const storedHistory = sessionStorage.getItem('navigationHistory');
    return storedHistory ? JSON.parse(storedHistory) : [];
  });

  const handlePathChange = (pathname: string, search: string, state: any) => {
    tgBtn.hide();
    const currentPathWithQuery = `${pathname}${search}`;
    let goBack = tgBackButtonParams.show?.find((item) => item.pathName === pathname);

    if (tgBackButtonParams.hide.includes(pathname)) {
      tgBtn.hide();
    } else if (goBack && goBack?.goBack !== -1) {
      tgBtn.show();
      tgBtn.onClick(() => {
        navigate(`${goBack.goBack}${search}`);
      });
    } else {
      tgBtn.show();
      tgBtn.onClick(() => {
        if (history.length > 1) {
          const newHistory = [...history];
          const previousRoute = newHistory[newHistory.length - 1];
          setHistory(newHistory.slice(0, -1));
          sessionStorage.setItem('navigationHistory', JSON.stringify(newHistory.slice(0, -1))); // Обновляем историю в sessionStorage
          navigate(previousRoute.path, {state: previousRoute.state});
        } else {
          navigate(ROUTE_PATHS.HOME);
        }
      });
    }

    if (previousPath.current !== currentPathWithQuery) {
      const newHistory = [...history, {path: currentPathWithQuery, state: state || null}];
      setHistory(newHistory);
      sessionStorage.setItem('navigationHistory', JSON.stringify(newHistory)); // Сохраняем новую историю в sessionStorage
      previousPath.current = currentPathWithQuery;
    }
  };

  useEffect(() => {
    handlePathChange(location.pathname, location.search, location.state);
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (tgId && tgId !== 'null') {
      saveHashedTgIdToLocalStorage(tgId);
    }
  }, [tgId]);

  return null;
};

export default PathListener;
