import React, { useEffect, useState } from 'react';

import { ReactComponent as DeliverIcon } from '../../assets/icons/deliverIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrowRight.svg';


import styles from './ParcelDeliverPage.module.css';
import { DESTINATIONS, useSendParcel } from '../../context/SendParcelContext';
import { formatDate, formatFullDate } from '../../utils/dateUtil';
import { Button } from '../../components/Button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { useDeliverParcel } from '../../context/DeliverParcelContext';
import { useRecentSearch } from '../../context/RecentSearchContext';
import { ROUTE_PATHS } from '../../const/routes';

const ParcelDeliverPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {t} = useTranslation();

  const [recentSearchResults, setRecentSearchResults] = useState<any[]>([]);
  const {handleGetRecentSearch} = useRecentSearch();
  const {handleDestinationChange} = useSendParcel();
  const {
    to,
    from,
    dateRange,
    deliverParcelSizes,
    handleDateRangeChange,
    handleFromChange,
    handleToChange,
    handleDeliverParcelSizesChange
  } = useDeliverParcel();




  const handleFetchRecentResults = async () => {
    const data = await handleGetRecentSearch(searchParams.get('hashed-tg-id') as string, 'deliver')

    if (data) {
      setRecentSearchResults(data)
    }
  }

  const handleNavigateToSearchResultsPageFromRecent = (from: string | { title: string, placeId: string }, to: string | {
    title: string,
    placeId: string
  }, deliverParcelSizes: string[], dateRange: { startDate: Date, endDate: Date }) => {

    //@ts-ignore
    from && handleFromChange(from)

    //@ts-ignore
    to && handleToChange(to)
    //@ts-ignore
    // deliverParcelSizes ? handleDeliverParcelSizesChange(deliverParcelSizes) : handleDeliverParcelSizesChange([]);

    // dateRange && handleDateRangeChange([{startDate: dateRange.startDate, endDate: dateRange.endDate, key: 'selection'}])

    //TODO for test
    // navigate(`${ROUTE_PATHS.CHECK_YOUR_DELIVER}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${searchParams.get('hashed-tg-id')}&from=${from}&to=${to}&parcel_size=${deliverParcelSizes}&date_range=${JSON.stringify(dateRange)}`, {
    //     state: {
    //         from,
    //         to,
    //         deliverParcelSizes,
    //         dateRange
    //     }
    // })

  }

  const handleNavigateToSelectLocationPage = () => {
    navigate(`${ROUTE_PATHS.DELIVER_SELECT_LOCATION}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${searchParams.get('hashed-tg-id')}`)
  }

  const handleNavigateToSelectDateRangePage = () => {
    navigate(`${ROUTE_PATHS.DELIVER_PARCEL_DATE_RANGE}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${searchParams.get('hashed-tg-id')}`)
  }

  const handleNavigateToSelectParcelSizePage = () => {
    navigate(`${ROUTE_PATHS.DELIVER_PARCEL_SIZE_SELECT}?tg-id=${searchParams.get('tg-id')}&hashed-tg-id=${searchParams.get('hashed-tg-id')}`)
  }

  const onToDestinationClick = () => {
    handleDestinationChange(DESTINATIONS.to)
    handleNavigateToSelectLocationPage();
  }

  const onFromDestinationClick = () => {
    handleDestinationChange(DESTINATIONS.from)
    handleNavigateToSelectLocationPage()
  }

  useEffect(() => {
    handleFetchRecentResults();
    // @ts-ignore

  }, []);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.topWidget}>
        <DeliverIcon/>
        <h1 className={styles.title}>{t('parcel_deliver_page.title')}</h1>
        <p className={styles.subTitle}>{t('parcel_deliver_page.subtitle')}</p>
      </div>

      <div className={styles.destinationWidget}>
        <div onClick={onFromDestinationClick} className={styles.destination}>
          <span>{from.title || t('parcel_deliver_page.from_placeholder')}</span>
          <ArrowRightIcon/>
        </div>
        <div onClick={onToDestinationClick} className={styles.destination}>
          <span>{to.title || t('parcel_deliver_page.to_placeholder')}</span>
          <ArrowRightIcon/>
        </div>
        <div onClick={handleNavigateToSelectDateRangePage} className={styles.destination}>
          <span>{dateRange ? `${formatDate(dateRange[0].startDate)} - ${formatDate(dateRange[0].endDate)}` : t('parcel_deliver_page.dates_placeholder')}</span>
          <ArrowRightIcon/>
        </div>
      </div>

      <Button disabled={!(from.title && to.title && dateRange)} text={t('parcel_deliver_page.continue_button')} size={'large'}
              color={'fill'} onClick={handleNavigateToSelectParcelSizePage}/>
      {recentSearchResults.length > 0 && (
        <div className={styles.recentSearchBlock}>
          <h2 className={styles.recent}>{t('recent')}</h2>
          <div className={styles.searchItems}>
            {recentSearchResults.slice(0, 5).map((result) => (
              <div
                onClick={() => handleNavigateToSearchResultsPageFromRecent(result.from, result.to, result.deliverParcelSizes, {
                  startDate: new Date(JSON.parse(result?.startDate)),
                  endDate: new Date(JSON.parse(result?.endDate))
                })} className={styles.resultItem}>
                <div className={styles.resultInfo}>
                  <p>
                    <span>{result.from.title || result.from}</span> - <span>{result.to.title || result.to}</span>

                  </p>
                  <p
                    className={styles.infoText}>{formatFullDate(new Date(JSON.parse(result?.startDate)))} - {formatFullDate(new Date(JSON.parse(result?.endDate)))}</p>
                  <div className={styles.ratingWrapper}>
                    <ArrowRightIcon/>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Navigation/>
    </div>
  );
};

export default ParcelDeliverPage;
