import React from 'react';
import styles from './styles.module.css';
import ParcelStatus, { EParcelStatus } from '../ParcelCard/ParcelStatus';
import { ReactComponent as BoxIcon } from './../../assets/icons/sendParcelIcon.svg';
import { ReactComponent as BusIcon } from './../../assets/icons/busParcelIcon.svg';
import { getCardFormat } from '../../utils/dateUtil';

export enum EImgType {
  BOX,
  BUS,
}

interface IProps {
  status?: EParcelStatus;
  imgType?: EImgType;
  dateRange: { from: string; to: string };
  place: { from: string; to: string };
  size?: string;
  price_detail?: {
    key: string,
    value: number
  }
}

const ParcelDetail: React.FC<IProps> = (props) => {
  console.log(props,222)
  const Icon = props.imgType == EImgType.BOX ? BoxIcon : BusIcon;
  return (
    <div className={styles.wrapper}>
      <div className={styles.firstPart}>
        <Icon height={24} width={36}/>
        {props.status && <ParcelStatus status={props.status}/>}
      </div>

      <div className={styles.secondPart}>
        <div className={styles.info}>
          <p className={styles.place}>
            {props.place.from} - {props.place.to}
          </p>
          <p className={styles.date}>
            {getCardFormat(props.dateRange.from)} -{' '}
            {getCardFormat(props.dateRange.to)}
          </p>
        </div>
        {props.size && <p className={styles.size}>{props.size}</p>}
      </div>
      {props.price_detail && <div className={styles.priceDetail}>
          <p className={styles.priceDetailKey}>{props.price_detail.key}</p>
          <p className={styles.priceDetailValue}>{props.price_detail.value}$</p>
      </div>}
    </div>
  );
};

export default ParcelDetail;
