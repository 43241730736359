import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import ParcelDetail, { EImgType } from '../../components/ParcelDetail';
import { EParcelStatus, mapParcelStatus } from '../../components/ParcelCard/ParcelStatus';
import ParcelCard from '../../components/ParcelCard';
import AdditionalInfoBlock from '../../components/AdditionalInfoBlock';
import PhotoDisplayList from '../../components/PhotoDisplayList';
import {
  Courier, deleteActiveParcelById,
  EParcelRequestStatus,
  getActiveParcelById,
  ParcelInfo, Sender,
  updateParcelStatus
} from '../../service/tracking/tracking';
import { useQuery } from '../../utils/useQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { useModal } from '../../context/ModalContext';
import RejectRequestModal from '../../components/Modals/RejectRequest';
import ApproveRequestModal from '../../components/Modals/ApproveRequest';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ROUTE_PATHS } from '../../const/routes';


const SenderDeliverDetailPage = () => {
  const location = useLocation()

  const {t} = useTranslation();

  const query = useQuery();
  const id = query.get('id');
  const isSender = location?.state;
  const [userInfo, setUserInfo] = useState<Courier | Sender>()
  const [parcelInfo, setParcelInfo] = useState<ParcelInfo>()

  useEffect(() => {
    id && getActiveParcelById(+id).then(data => {
      setParcelInfo(data)
      if (isSender) {
        setUserInfo(location.state)
      } else {
        setUserInfo(data?.courier)
      }

    })

  }, []);

  const navigate = useNavigate()

  const {openModal} = useModal();
  if (!parcelInfo) return null


  const currentStatus = mapParcelStatus(parcelInfo.status)
  //TODO for test
  // const currentStatus = EParcelStatus.APPROVED as EParcelStatus
  const handleRejectRequest = () => {
    !isSender ?
      deleteActiveParcelById(parcelInfo.id).then(() => {
        navigate(-1)
      }) :
      updateParcelStatus({status: EParcelRequestStatus.Disapproved, id: parcelInfo.id}).then(() => {
        navigate(-1)
      })
  }
  const handleApproveRequest = () => {
    updateParcelStatus({status: EParcelRequestStatus.Approved, id: parcelInfo.id}).then(() => {
      navigate(-1)
    })
  }

  const handleNavigateToUserInfo = () => navigate(`${ROUTE_PATHS.USER_INFO}?id=${userInfo?.id}`);
  const handleOpenRejectRequestModal = () => {
    openModal(RejectRequestModal,
      {onRejectRequest: handleRejectRequest});
  };
  const handleOpenApproveRequestModal = () => {

    openModal(ApproveRequestModal,
      {onApproveRequest: handleApproveRequest});
  };
  const handleNavigateToGiveFeedback = () => navigate(ROUTE_PATHS.GIVE_FEEDBACK_PAGE, {
    state: {
      courierId: userInfo?.id,
      id: parcelInfo.id,
      name: userInfo?.name,
      avatar: userInfo?.photo_link
    }
  });
  const has24HoursPassed = moment().diff(parcelInfo.dispatch_period_start, 'hours') >= 24;

  const ButtonsArray = [
    {
      condition: currentStatus === EParcelStatus.APPROVED && !isSender,
      buttons: [
        {
          color: 'fill',
          text: t('sender_deliver_detail_page.go_to_chat'),
          action: () => {
            //TODO
            //@ts-ignore
            window.Telegram.WebApp.openLink(parcelInfo.delivery_chat);
            // navigate(ROUTE_PATHS.CHAT_PAGE)
          }
        },
        {
          color: 'bezeled',
          text: t('sender_deliver_detail_page.cancel_request'),
          action: handleOpenRejectRequestModal
        }
      ]
    }, {
      condition: currentStatus === EParcelStatus.WAITING_FOR_APPROVAL && !isSender,
      buttons: [
        {
          color: 'bezeled',
          text: t('sender_deliver_detail_page.cancel_request'),
          action: handleOpenRejectRequestModal
        }
      ]
    },
    {
      condition: currentStatus === EParcelStatus.DELIVERED_GIVE_FEEDBACK && !isSender,
      buttons: [
        {
          color: 'fill',
          text: t('sender_deliver_detail_page.give_feedback'),
          action: handleNavigateToGiveFeedback
        }
      ]
    },
    {
      condition: currentStatus === EParcelStatus.APPROVED && isSender,
      buttons: [
        {
          isActive: !has24HoursPassed,
          color: 'fill',
          text: t('sender_deliver_detail_page.parcel_delivered'),
          action: handleNavigateToGiveFeedback
        },
        {
          color: 'bezeled',
          text: t('sender_deliver_detail_page.cancel_request'),
          action: handleOpenRejectRequestModal
        }
      ]
    },

    {
      condition: currentStatus === EParcelStatus.DELIVERED_GIVE_FEEDBACK && isSender,
      buttons: [
        {
          color: 'fill',
          text: t('sender_deliver_detail_page.give_feedback'),
          action: handleNavigateToGiveFeedback
        }
      ]
    },
    {
      condition: currentStatus === EParcelStatus.WAITING_FOR_APPROVAL && isSender,
      info: t('sender_deliver_detail_page.waiting_approval_info'),
      buttons: [
        {
          color: 'fill',
          text: t('sender_deliver_detail_page.approve_parcel'),
          action: handleOpenApproveRequestModal
        },
        {
          color: 'bezeled',
          text: t('sender_deliver_detail_page.reject'),
          action: handleOpenRejectRequestModal
        }
      ]
    },
  ]
  const getButtonsByStatus = () => {
    const currentButton = ButtonsArray.find(item => item.condition)
    return (
      <footer className={styles.footer}>
        {currentButton?.info && <p>{currentButton?.info}</p>}
        {currentButton?.buttons.map(btn => (
          <div className={styles.button}>
            <Button
              disabled={btn?.isActive}
              text={btn.text}
              color={btn.color}
              size="large"
              onClick={btn.action}
            />
          </div>
        ))}
      </footer>
    )
  }

  return (
    <>
      <div className={styles.wrapper}>
        <ParcelDetail
          imgType={EImgType.BOX}
          dateRange={{from: parcelInfo.dispatch_period_start, to: parcelInfo.dispatch_period_end}}
          size={parcelInfo.size}
          place={{from: parcelInfo?.departure_point, to: parcelInfo?.destination_point}}
          status={currentStatus}
          price_detail={parcelInfo?.price_detail}
        />
        <ParcelCard
          onClick={handleNavigateToUserInfo}
          avatarImg={userInfo?.photo_link}
          name={userInfo?.name || ''}
          rating={userInfo?.rating}
        />
        {currentStatus == EParcelStatus.APPROVED && isSender && <p className={styles.goToChat} onClick={() => {
          //@ts-ignore
          window.Telegram.WebApp.openLink(parcelInfo?.delivery_chat);
        }}>{t('sender_deliver_detail_page.go_to_chat')}</p>}
        <AdditionalInfoBlock
          text={
            parcelInfo.additional_info
          }
        />
        <PhotoDisplayList data={parcelInfo.photos}/>


      </div>
      {getButtonsByStatus()}
    </>
  );
};
export default SenderDeliverDetailPage;
