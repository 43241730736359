import React from 'react';
import styles from './styles.module.css';
import GalleryIcon from './../../assets/icons/gallery.svg';
import { UploadPhotoBlock } from '../../components/UploadPhotoBlock';
import { Button } from '../../components/Button/Button';
import {useNavigate} from 'react-router-dom';
import { useQuery } from '../../utils/useQuery';
import {useTranslation} from "react-i18next";
import {usePhotos} from "../../context/PhotosContext";
import TextArea from '../../components/TextArea';
import { ROUTE_PATHS } from '../../const/routes';
export const AdditionalInfoPage = () => {
  const query = useQuery();
  const id = query.get('id');
  const tripId = query.get('tripId');
  const navigate = useNavigate()

  const { t } = useTranslation();
  const { info, handleUpdatePhoto, handleInfoChange, photos } = usePhotos()

  const handleSubmit = () => {
    navigate(`${ROUTE_PATHS.ADDITIONAL_INFO_DETAIL_PAGE}?id=${id}&tripId=${tripId}`)
  }


  return (
    <>
     <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.info}>
          <img src={GalleryIcon} alt="GalleryIcon" />
          <h2>{t('additional_info_page.title')}</h2>
          <p>
            {t('additional_info_page.description')}
          </p>
        </div>

        <p className={styles.label}>{t("additional_info_page.additional_info_label")}</p>
        <TextArea
          minHeight={100}
          value={info || ""}
          onChange={handleInfoChange}
          className={styles.textArea}
          placeholder={t('additional_info_page.placeholder')}
        />
        <div className={styles.photos}>
          <h3>{t('additional_info_page.photos_title')}</h3>
          <p>{t('additional_info_page.photos_description')}</p>
          <UploadPhotoBlock onHandleUploadPhoto={handleUpdatePhoto}/>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.button}>
          <Button
            text="Submit"
            color="fill"
            size="large"
            onClick={handleSubmit}
            disabled={!(info)}
          />
        </div>
      </footer>
    </>
  );
};
