import SetRating from '../../components/SetRating';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import styles from './styles.module.css'
import { Button } from '../../components/Button/Button';
import { createNewReview } from '../../service/parcel/parcel';
import { EToast, useToast } from '../../context/ToastContext';
import { getProfileFromLocalStorage } from '../../utils/storeActions';
import { useTranslation } from 'react-i18next';
import { EParcelRequestStatus, patchTrip, updateParcelStatus } from '../../service/tracking/tracking';
import { ROUTE_PATHS } from '../../const/routes';

const GiveFeedBackPage = () => {
  const navigate = useNavigate()
  const {showToast} = useToast()

  const {t} = useTranslation();

  const profileData = getProfileFromLocalStorage()
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const location = useLocation();
  const {id, name, avatar, courierId} = location.state || {};

  const handleRatingSubmit = (rating: number) => {
    setRating(rating)
  };
  if (!profileData) return null
  const handleSubmit = () => {
    updateParcelStatus({status: EParcelRequestStatus.Delivered, id: Number(id)}).then(() => {
      createNewReview({
        recipient: courierId,
        author: profileData.id,
        rating: String(rating),
        text: comment,
        delivery_request: id
      }).then(() => {
        navigate(ROUTE_PATHS.TRACKING)
        showToast(t('give_feedback_page.feedback_success'), EToast.ACCEPT)
      })
    })
  }

  return (
    <>
      <div className={styles.wrapper}>
        <Avatar profileName={name} avatarUrl={avatar}/>
        <p className={styles.info}>{t('give_feedback_page.rate_experience')}
          <br/>{t('give_feedback_page.rate_experience2')} {name}?</p>
        <SetRating onRatingSubmit={handleRatingSubmit}/>
        <p className={styles.label}>{t('give_feedback_page.comment_label')}</p>
        <textarea
          value={comment}
          onChange={(event) =>
            setComment(event.target.value)
          }
          className={styles.textArea}
          placeholder={t('give_feedback_page.comment_placeholder')}
        ></textarea>
      </div>
      <footer className={styles.footer}>
        <div className={styles.button}>
          <Button
            text={t('give_feedback_page.submit_button')}
            color="fill"
            size="large"
            onClick={handleSubmit}
            disabled={!(rating && comment)}
          />
        </div>
      </footer>
    </>

  )
}

export default GiveFeedBackPage
