import React from "react";
import styles from './styles.module.css'
import { PhotoDisplay } from "../PhotoDisplay";

interface IProps {
  data: any;
}
const PhotoDisplayList: React.FC<IProps> = ({data}) => {
  return (
    <div className={styles.wrapper}>
      {data.map((photo) => (
        <PhotoDisplay photo={photo?.photo_link || photo?.photo} />
      ))}
    </div>
  );
};
export default PhotoDisplayList;
