import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrowRight.svg';


import { getCardFormat } from '../../utils/dateUtil';
import { Button } from '../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import { getNameBySize } from '../../components/TripDetails';
import { useQuery } from '../../utils/useQuery';
import { patchTrip } from '../../service/tracking/tracking';
import { useTranslation } from 'react-i18next';
import style from '../EditProfile/editProfile.module.css';
import { calculatePrices } from '../../utils/calculatePrices';

import { ReactComponent as InfoIcon } from '../../assets/icons/infoIcon.svg';
import { useModal } from '../../context/ModalContext';
import LocationSelectModal from '../../components/Modals/LocationSelectModal';
import DateRangeSelectModal from '../../components/Modals/DateRangeSelecModal';
import moment from 'moment';
import TextArea from '../../components/TextArea';
import { ROUTE_PATHS } from '../../const/routes';

const EditInfoPage = () => {
  const {openModal} = useModal()


  const {t} = useTranslation();

  const navigate = useNavigate();
  const location = useLocation()
  const query = useQuery();
  const id = query?.get('id');
  const {
    deliverParcelSizes,
    additionalInfo: additionalInfoOld,
    dateRange: oldDateRange,
    from: oldFrom,
    to: oldTo
  } = location.state;
  const [additionalInfo, setAdditionalInfo] = useState(additionalInfoOld || '')
  const additionalInfoRef = useRef<HTMLTextAreaElement>(null);
  const [from, setFrom] = useState(oldFrom)
  const [to, setTo] = useState(oldTo)
  const [dateRange, setDateRange] = useState<any>(oldDateRange)

  const onEditClick = () => {
    navigate(`${ROUTE_PATHS.TRACKING_SIZE_SELECT}?id=${id}`, {
      state: {
        deliverParcelSizes,
        additionalInfo,
        dateRange,
        from,
        to
      }
    })
  }
  const handleFocus = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) {
          window.scrollBy({
            top: window.innerHeight,
            behavior: 'smooth',
          });
        }
      }, 1000);
    }
  };
  const handleDone = () => {

    id && patchTrip({
      updatedDetails: {
        additional_info: additionalInfo,
        // sizes: []
        sizes_available: deliverParcelSizes
      }, id: +id
    }).then(() => {
      navigate(`${ROUTE_PATHS.COURIER_DELIVER_DETAIL_PAGE}?id=${id}`)
    })

  }


  const onPossibleEarningsClick = () => {
    navigate(`${ROUTE_PATHS.POSSIBLE_EARNINGS}`);
  }


  const resultSizes = calculatePrices(deliverParcelSizes)

  const handleSelectFrom = ({title, placeId}: { title: string, placeId: string }) => {
    setFrom(title)
    id && patchTrip({
      updatedDetails: {
        departure_point: placeId,

      }, id: +id
    })

  }
  const handleSelectTo = ({title, placeId}: { title: string, placeId: string }) => {
    setTo(title)
    id && patchTrip({
      updatedDetails: {
        destination_point: placeId,

      }, id: +id
    })

  }
  useEffect(() => {
    if (location?.state?.newDestination) {
      location.state?.isTo ? handleSelectTo(location?.state?.newDestination) : handleSelectFrom(location?.state?.newDestination)
    }
  }, [location?.state?.newDestination]);
  useEffect(() => {
    if (location?.state?.newDateRange) {
      onChangeDateRange(location?.state?.newDateRange.startDate,location?.state?.newDateRange.endDate)
    }
  }, [location?.state?.newDateRange]);
  const handleChangeLocation = (isTo: boolean) => {
    navigate(ROUTE_PATHS.CUSTOM_SELECT_LOCATION + '?id=' + id, {
      state: {
        isTo,
        deliverParcelSizes,
        additionalInfo,
        dateRange,
        from,
        to
      }
    })
  }
  const onChangeDateRange = (startDate: string, endDate: string) => {
    setDateRange({startDate, endDate})
    id && patchTrip({
      updatedDetails: {
        dispatch_period_start: moment(startDate).format('DD.MM.YYYY'),
        dispatch_period_end: moment(endDate).format('DD.MM.YYYY'),
      }, id: +id
    })
  }

  const handleChangeDateRange = () => {
    console.log(dateRange, 'dateRange')
    navigate(ROUTE_PATHS.CUSTOM_DATE_RANGE_SELECT + '?id=' + id, {
      state: {...location.state, dateRange}

    })
    // navigate()
    // openModal(DateRangeSelectModal, {
    //   onChange: onChangeDateRange,
    //   dateRange
    // })
  }
  return (
    <div className={styles.pageWrapper}>
      <h2 className={styles.subTitle}>{t('edit_info_page.destination_and_dates')}</h2>
      <div className={styles.destinationWidget}>

        <div className={styles.destination} onClick={() => handleChangeLocation(false)}>
          <span>{from || t('check_your_delivery_page.from')}</span>
          <ArrowRightIcon/>
        </div>
        <div className={styles.destination} onClick={() => handleChangeLocation(true)}>
          <span>{to || t('check_your_delivery_page.to')}</span>
          <ArrowRightIcon/>
        </div>
        <div className={styles.destination} onClick={handleChangeDateRange}>
          <span>{dateRange ? `${getCardFormat(dateRange.startDate)} - ${getCardFormat(dateRange.endDate)}` : t('check_your_delivery_page.dates')}</span>
          <ArrowRightIcon/>
        </div>
      </div>


      <h2 className={styles.subTitle}>{t('check_your_delivery_page.size')}</h2>
      <div className={styles.sizeWidget}>
        {Object.entries(deliverParcelSizes)?.map(size => {

          const price = resultSizes.resultArray.find(item => item.size == size[0])
          const label = getNameBySize(size[0])
          return label && (
            <div className={styles.row}>
              <p className={styles.sizeText}>
                {label}
              </p>
              <p className={styles.price}>{price?.price}$</p>
            </div>
          )
        })}
        <div className={styles.sizeWidgetBottom}>
          <p className={styles.widgetText}>{t('check_your_delivery_page.possible_earnings')}<InfoIcon
            onClick={onPossibleEarningsClick}/></p>
          <p className={styles.totalPrice}>≈ {resultSizes.total}$</p>
        </div>
      </div>


      <p className={styles.subtext}>{t('check_your_delivery_page.edit_text')} <span
        onClick={onEditClick}>{t('check_your_delivery_page.edit')}</span></p>

      <div className={styles.recentSearchBlock}>
        <h2 className={styles.subTitle}>{t('check_your_delivery_page.additional_info')}</h2>
        {/*<div className={styles.textArea}>{additionalInfo}</div>*/}
        <TextArea
          // ref={additionalInfoRef}
          onFocus={() => handleFocus(additionalInfoRef)}
          value={additionalInfo || ''}
          onChange={setAdditionalInfo}
          className={style.textArea}
          placeholder={t('additional_info_page.placeholder')}
        ></TextArea>
      </div>

      <div className={styles.bottomBlock}>

        <Button text={t('edit_info_page.done_button')} size={'large'} color={'fill'} onClick={handleDone}/>
      </div>
    </div>
  );
};

export default EditInfoPage;
