import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import globalRequestStore from '../../store';
import { useToast } from '../../context/ToastContext';
import Loader from '../Loader';
import { type } from 'os';


const GlobalLoaderAndError = observer(() => {
  const {showToast, showMultiToast} = useToast();

  useEffect(() => {
    if (globalRequestStore.error) {
      if (Array.isArray(globalRequestStore.error)) {


        showMultiToast(globalRequestStore.error.map((item: any) => {
          return {
            message: item.field + ': ' + item.message,
            type: 'decline'
          }
        }))
      } else {
        showToast(typeof globalRequestStore.error === 'string' ? globalRequestStore.error : JSON.stringify(globalRequestStore.error), 'decline');
      }
    }
  }, [globalRequestStore.error, showToast]);

  return (
    <>
      {globalRequestStore.isLoading && (
        <Loader/>
      )}
    </>
  );
});

export default GlobalLoaderAndError;
